import React, { useState, useEffect, useRef } from "react";
import OgImg from "../assets/images/raster/components/common/mainscreen.png";
import { PAGE_OPTIONS, PAGES } from "../helpers/constants";
import { PG_AT_CONTENT } from "../components/amazon-toolkit/index.content";
import RATING_NOW_CONTENT from "../components/amazon-toolkit/Reviews/index.content";
import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import HomeIntro from "../components/amazon-toolkit/HomeIntro";
import ChromeRating from "../components/common/ChromeRating";
import PartnerBrand from "../components/common/PartnerBrand";
import Testimonials from "../components/common/TestimonialsSection";
import Tools from "../components/amazon-toolkit/Tools";
import Last from "../components/amazon-toolkit/Last";
import FormWithPicture from "../components/common/Forms/FormWithPicture";
import RatingNow from "../components/common/RatingNow";
import CustomizableContainer from "../containers/Paywalls/Customizable/CustomizableContainer";
import ReasonsToUse from "../components/amazon-toolkit/ReasonsToUse";
import DealsBanner from "../components/amazon-toolkit/DealsBanner";
import DealsBannerContainer from "../containers/common/DealsBannerContainer";
import EmptySpace from "../components/amazon-toolkit/EmptySpace";
import "../components/amazon-toolkit/index.scss";
import "../components/amazon-toolkit/Reviews/index.scss";

const PgAT = () => {
  const [getIsScrolled, setIsScrolled] = useState(false);
  const [isShowYoutubeModal, setIsShowYoutubeModal] = useState(false);
  const [urlYoutubeModal, setUrlYoutubeModal] = useState("");
  const tariffs = useRef(null);
  // const formRef = useRef(null);

  useEffect(() => {
    if (window.location.hash) {
      setIsScrolled(
        window.location.hash.substring(1).split("?")[0] === "pricing"
      );
    }
  }, []);

  useEffect(() => {
    setIsShowYoutubeModal(!!urlYoutubeModal);
  }, [urlYoutubeModal]);

  const closeYoutubeModal = () => setUrlYoutubeModal("");

  // const handleClickOneMiddleForm = () => {
  //   if (isSecondStateCollectEmail) {
  //     window.open(PAGE_OPTIONS[PAGES.AMAZON_TOOLKIT].REDIRECTED_URL, "_blank");
  //   } else {
  //     formRef?.current.scrollIntoView({
  //       behavior: "smooth",
  //       block: "center",
  //     });
  //   }
  // };

  if (getIsScrolled) {
    tariffs.current.scrollIntoView({ block: "start", behavior: "smooth" });
    setIsScrolled(false);
  }

  return (
    <Layout
      urlYoutubeModal={urlYoutubeModal}
      tariffsElement={tariffs}
      isShowYoutubeModal={isShowYoutubeModal}
      closeYoutubeModal={closeYoutubeModal}
      customClass="PgAT"
      isBundlePanel
    >
      <Seo
        title="Amazon Toolkit for Dropshipping, Arbitrage, and Wholesale Sellers"
        description={
          "Get an Amazon toolkit and start selling with a step-by-step course. Grow your dropshipping, arbitrage, or wholesale business with AMZScout's extensive bundle. Try for free."
        }
        page="amazon-toolkit"
        manifest="browserconfig-index.xml"
        ogImg={OgImg}
        keywords={["amazon toolkit", "amazon toolset"]}
      />
      <HomeIntro />
      <ChromeRating />
      <PartnerBrand />
      <RatingNow customClass="PgAT-reviews" {...RATING_NOW_CONTENT} />
      <ReasonsToUse />
      <Tools />
      <Testimonials
        setUrlYoutubeModal={setUrlYoutubeModal}
        title="Empowering famous sellers and AMZScout residents"
        customClass="PgAT-testimonials"
      />
      <CustomizableContainer
        pricingRef={tariffs}
        customClass="PgAT-Pricing"
        advancedReselling
        subTitlePro
      />
      <Last />
      <FormWithPicture
        content={PG_AT_CONTENT.form3}
        page={PAGES.AMAZON_TOOLKIT}
        redirectUrl={PAGE_OPTIONS[PAGES.AMAZON_TOOLKIT].REDIRECTED_URL}
      />
      <EmptySpace isShow={process.env.DEALS_TOOLKIT_SECTION} />
      <DealsBannerContainer
        dealsSection={process.env.DEALS_TOOLKIT_SECTION}
        dealsVersion={process.env.DEALS_TOOLKIT_VERSION}
        dealsUrl={process.env.DEALS_TOOLKIT_URL}
        label="Get Holiday Deals!"
        category="Index"
        alwaysShowDeals
      >
        <DealsBanner hideCloseButton />
      </DealsBannerContainer>
    </Layout>
  );
};

export default PgAT;
